<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--12-xs(v-if="needSearch")
      tag(
        :tag="$options.widgetsMap['search']"
        :parameters="parameters"
        :value="value['query']"
        :disabled="disabled"
        @input="updateValue('query', $event)"
      )
    +b.g-cell.g-cols.--12-xs(
      v-for="filter in filters"
      :class="{ 'is-visible is-hidden-xl': filter.urlKey === 'statuses' && portalStatuses }"
    )
      tag(
        v-if="filter.urlKey === 'geo_path' || filter.urlKey === 'geo_path_short'"
        :is-short="filter.urlKey === 'geo_path_short'"
        :fullwidth="true"
        :tag="$options.widgetsMap['LocationWidget']"
        :value="preparedValues[filter.urlKey]"
        :ref="filter.urlKey"
        @input="updateValue(filter.urlKey, $event)"
      )
      div(v-else-if="filter.urlKey === 'statuses' && portalStatuses")
        tag(
          :tag="$options.widgetsMap[filter.type]"
          :parameters="parameters"
          :value="preparedValues[filter.urlKey]"
          :filter-name="filter.urlKey"
          :disabled="disabled"
          :filter="filter"
          @input="updateValue(filter.urlKey, $event)"
        )
        portal(to='statuses')
          tag(
            :tag="$options.widgetsMap[filter.type]"
            :parameters="parameters"
            :value="preparedValues[filter.urlKey]"
            :filter-name="filter.urlKey"
            :disabled="disabled"
            :filter="filter"
            @input="updateValueImmediate(filter.urlKey, $event)"
          )
      tag(
        v-else
        :tag="filter.choiceUrl ? $options.widgetsMap['AutocompleteChoiceFilter'] : $options.widgetsMap[filter.type]"
        :parameters="parameters"
        :value="preparedValues[filter.urlKey]"
        :filter-name="filter.urlKey"
        :disabled="disabled"
        :filter="filter"
        :multiple-autocomplete="filter.type === 'ModelMultipleChoiceFilter'"
        @input="'DateFromToRangeFilter' === filter.type ? updateMultipleValues(filter.urlKey, $event) : updateValue(filter.urlKey, $event)"
      )
</template>

<script>
import UiMixin from './mixins/UiMixin'

export default {
  mixins: [UiMixin],

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'needSearch',
    'portalStatuses',
  ],
}
</script>
